<template>
    <div>
        <div class="footer-container">
            <small>All booked rides by UP LIMO are dispatched by Aureus LLC & Limo Inc. TLC N# B03018 252 Java St. Brooklyn NY 11222</small>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>