<template>
    <div>
        <Header ref="webHeader"></Header>
        <router-view :key="$route.path" class="web-container" ref="webContainer"></router-view>
        <Footer ref="webFooter"></Footer>
    </div>
  
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
export default {
    name:'Container',
    components: {
        Header,
        Footer
    },
    data() {
        return {

        }
    },
    methods: {
        setMainContainerHeight() {
            // viewport height
            // header height
            // footer height
            // set container min-height
            console.log(window)
            console.log(this.$refs)
            let headerHeight = this.$refs.webHeader.$el.offsetHeight;
            let footerHeight = this.$refs.webFooter.$el.offsetHeight;
            let containerHeight = this.$refs.webContainer.$el.offsetHeight;

            let idealHeight = window.innerHeight - ( headerHeight + footerHeight)
            console.log('containerHeight',containerHeight);
            console.log('idealHeight',idealHeight);
            if(containerHeight < idealHeight) {
                this.$refs.webContainer.$el.style.minHeight = `${idealHeight}px`
            }  
            
        }
    },
    
    created() {
        //this.setMainContainerHeight()
        let th = this
        //window.addEventListener('resize', th.setMainContainerHeight);        
    },
    destroyed() {
        let th = this
        //window.removeEventListener('resize', th.setMainContainerHeight);
    },
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import './../scss/style';

 
</style>