export default [
    {
        _name: "CSidebarNav",
        _children: [
            {
                route:'',
                text: 'Products',
                icon: '',
                items: [
                    
                    {
                        text:'Reservation system',
                        route:'reservation-system',
                    },
                    {
                        text:'Dispatch system',
                        route:'',
                    },
                    {
                        text:'Passenger App',
                        route:'',
                    },
                    {
                        text:'Driver App',
                        route:'',
                    },
                    {
                        text:'Driver Offers System',
                        route:'',
                    },
                    {
                        text:'Classified Drivers Community',
                        route:'',
                    },
                    {
                        text:'Classified Dispatchers Community',
                        route:'',
                    },
                    {
                        text:'Classified Limo Companies Community',
                        route:'',
                    },
                ]
            },
            {
                route:'',
                text: 'Features',
                items: [
                    {
                        text:'Geofences System',
                        route:'',
                    },
                    {
                        text:'Receipt or posting of travel offers',
                        route:'',
                    },
                    {
                        text:'Increase or reduction of rates for certain areas',
                        route:'',
                    },
                    {
                        text:'Automatic application of airport taxes and tolls',
                        route:'',
                    },
                    {
                        text:'System of rates by time, distance or flat',
                        route:'',
                    },
                    {
                        text:'Multi-time system to dispatch jobs in areas with different time zones',
                        route:'',
                    },
                    {
                        text:'Dynamic fee add-ons',
                        route:'',
                    },
                    {
                        text:'Dynamic taxable and non-taxable fee amount',
                        route:'',
                    },
                    {
                        text:'Adhesion of the cost of tolls automatically',
                        route:'',
                    },
                    {
                        text:'Automatic driver license check by TLC',
                        route:'',
                    },
                    {
                        text:'Automatic car insurance check by TLC',
                        route:'',
                    },
                    {
                        text:'Special dynamic reports of all kinds (Automatic TLC Report)',
                        route:'',
                    },
                ]
            },
            {
                route:'pricing',
                text: 'Pricing',
                icon: '',
                items: []
            },
            {
                route:'contact-us',
                text: 'Contact us',
                icon: '',
                items: []
            },
            {
                route:'documentation',
                text: 'Documentation',
                icon: '',
                items: []
            }

        ]
    }
]