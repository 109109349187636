export default [
    {
        _name: "CSidebarNav",
        _children: [
            
            {
                route:'privacy-policy',
                text: 'Privacy policy',
                icon: '',
                items: []
            },
            {
                route:'terms-and-conditions',
                text: 'Terms and conditions',
                icon: '',
                items: []
            },
            {
                route:'web-terms-of-use',
                text: 'Web terms of use',
                icon: '',
                items: []
            }

        ]
    }
]