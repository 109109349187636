<template>     
    <div>
      <md-toolbar class="main-toolbar">
        <md-button 
            class="md-icon-button d-lg-none mr-2" 
            @click="menuVisible = !menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <a class="md-title ml-0" href="/" @click="goTo($event, '')">UpLimo</a>
        <div class="md-toolbar-section-end">
            <ul class="desktop-menu mb-0 pl-0 pr-0" v-if="viewport.width >= 992 && !temporary">
                <template v-for="(item, index) in nav[0]._children" >
                    <li 
                        v-bind:key="`isolated-desktop--${index}`"
                        v-if="item.items.length === 0"
                    >
                        <md-button :href="`/w/${item.route}`" @click="goTo($event, item.route)">{{item.text}}</md-button>
                    </li>
                    <md-menu 
                        md-size="small" 
                        md-align-trigger
                        v-bind:key="`dropdown-desktop-${index}`"
                        v-else-if="item.items.length > 0" 
                    >
                        <md-button md-menu-trigger>{{ item.text }}</md-button>

                        <md-menu-content>
                            <template v-for="(subitem, i) in item.items">
                                <md-menu-item
                                    v-bind:key="`subitem-desktop-${index}-${i}`"
                                    :href="`/w/${subitem.route}`" @click="goTo($event, subitem.route)"
                                >
                                    {{ subitem.text }}
                                </md-menu-item>
                            </template>
                        </md-menu-content>
                    </md-menu>
                </template>
                
            </ul>
            <ul class="desktop-menu mb-0 pl-0 pr-0" v-if="viewport.width >= 992 && temporary">

                <template v-for="(item, index) in temporaryNav[0]._children">
                        <li 
                            v-bind:key="`isolated-desktop--${index}`"
                            v-if="item.items.length === 0"
                        >
                            <md-button :href="`/w/${item.route}`" @click="goTo($event, item.route)">{{item.text}}</md-button>
                        </li>
                        <md-menu 
                            md-size="small" 
                            md-align-trigger
                            v-bind:key="`dropdown-desktop-${index}`"
                            v-else-if="item.items.length > 0" 
                        >
                            <md-button md-menu-trigger>{{ item.text }}</md-button>

                            <md-menu-content>
                                <template v-for="(subitem, i) in item.items">
                                    <md-menu-item
                                        v-bind:key="`subitem-desktop-${index}-${i}`"
                                        :href="`/w/${subitem.route}`" @click="goTo($event, subitem.route)"
                                    >
                                        {{ subitem.text }}
                                    </md-menu-item>
                                </template>
                            </md-menu-content>
                        </md-menu>
                </template>
            </ul>
            
            <md-button class="md-raised md-primary md-dense" v-if="user.loggedIn && !temporary" @click="goTo($event, '/app')">Go to App</md-button>
            <md-button class="md-raised md-primary md-dense" v-else-if="!user.loggedIn && !temporary">Free demo</md-button>
            <md-menu md-size="medium" md-align-trigger v-if="user.loggedIn && !temporary">
        
                <md-button class="md-icon-button" md-menu-trigger>
                    <md-icon>account_circle</md-icon>
                </md-button>
        
                <md-menu-content v-if="user.data" class="max-height-initial">
                    <md-list class="md-double-line md-dense">
                        <md-list-item class="min-height-auto">
                            <div class="md-body-2">Email &amp; role</div>
                        </md-list-item>
                        <md-list-item>
                            <div class="md-list-item-text">
                            <span>{{ user.data.email }}</span>
                            <span class="role mt-2 mb-3">{{ user.data.activeSubrole }} {{ user.data.activeRole }}</span>
                            </div>
                        </md-list-item>
                        <md-divider></md-divider>
                        <md-list-item class="min-height-auto mt-2" v-if="user.data.activeRole !== 'super-admin'">
                            <div class="md-body-2">Unique user code</div>
                        </md-list-item>
                        <md-list-item v-if="user.data.activeRole !== 'super-admin'">
                            <div class="md-list-item-text">  
                            <span class="code mb-3">{{ user.data.code }}</span>
                            </div>
                        </md-list-item>
                        <md-list-item>
                            <md-button @click="userSettings" class="md-raised md-primary full-width-button">User settings</md-button>
                        </md-list-item>
                        <md-divider></md-divider>
                        <md-list-item @click="this.$root.$children[0].signOut"
                            >
                            <md-icon class="mr-3 ">logout</md-icon>
                            <span class="md-list-item-text">Logout</span>  
                        </md-list-item>
                    </md-list>
                </md-menu-content>
            </md-menu>
            <md-menu md-size="small" md-align-trigger v-else-if="!user.loggedIn && !temporary">
                <md-button class="md-icon-button ml-2 md-raised md-dense" md-menu-trigger>
                    <md-icon>account_circle</md-icon>
                </md-button>
                <md-menu-content>
                    <md-menu-item @click="goTo($event,'login')">Login</md-menu-item>
                    <md-menu-item @click="goTo($event,'register')">Sign up</md-menu-item>
                </md-menu-content>
            </md-menu>
        </div>
      </md-toolbar>

      <md-drawer :md-active.sync="menuVisible" class="main-drawer">
        <md-toolbar class="md-transparent p-0" md-elevation="0">
            <h2 class="md-subheading pl-16 up-text-primary"> Menu</h2>
        </md-toolbar>
        <md-list :md-expand-single="true" v-if="viewport.width < 992 && !temporary">
            <template v-for="(item, index) in nav[0]._children">
                <md-list-item 
                    v-bind:key="`isolated-${index}`"
                     v-if="item.items.length === 0" :href="`/w/${item.route}`" @click="goTo($event, item.route)">
                    <!--md-icon>{{ item.icon}}</md-icon-->
                    <span class="md-list-item-text">{{ item.text }}</span>
                </md-list-item>
                <md-list-item 
                    md-expand 
                    v-bind:key="`dropdown-${index}`"
                    
                    v-else-if="item.items.length > 0" 
                >
                    <!--md-icon>{{item.icon}}</md-icon-->
                    <span class="md-list-item-text">{{ item.text }}</span>

                    <md-list slot="md-expand">
                        
                        <template v-for="(subitem, i) in item.items">
                            <md-list-item 
                            v-bind:key="`subitem-${index}-${i}`" 
                                class=""
                                :href="`/w/${subitem.route}`" @click="goTo($event, subitem.route)"
                            >
                             {{ subitem.text}}
                            </md-list-item>
                            
                        </template>
                    </md-list>
                </md-list-item>
            </template>
           
        </md-list>

        <md-list :md-expand-single="true" v-if="viewport.width < 992 && temporary">
            <template v-for="(item, index) in temporaryNav[0]._children">
                <md-list-item 
                    v-bind:key="`isolated-${index}`"
                     v-if="item.items.length === 0" :href="`/w/${item.route}`" @click="goTo($event, item.route)">
                    <!--md-icon>{{ item.icon}}</md-icon-->
                    <span class="md-list-item-text">{{ item.text }}</span>
                </md-list-item>
                <md-list-item 
                    md-expand 
                    v-bind:key="`dropdown-${index}`"
                    
                    v-else-if="item.items.length > 0" 
                >
                    <!--md-icon>{{item.icon}}</md-icon-->
                    <span class="md-list-item-text">{{ item.text }}</span>

                    <md-list slot="md-expand">
                        
                        <template v-for="(subitem, i) in item.items">
                            <md-list-item 
                            v-bind:key="`subitem-${index}-${i}`" 
                                class=""
                                :href="`/w/${subitem.route}`" @click="goTo($event, subitem.route)"
                            >
                             {{ subitem.text}}
                            </md-list-item>
                            
                        </template>
                    </md-list>
                </md-list-item>
            </template>
           
        </md-list>
      </md-drawer>

    </div>  

  
</template>

<script>
import nav from "./_menu";
import temporaryNav from "./_temporary_menu";
export default {
    name:'Header',
    data () {
        return {
            temporary: false,
            nav: nav,
            temporaryNav: temporaryNav,
            menuVisible: false,
            viewport: {
                width: null
            }
        }
    },
    methods: {
        windowResized() {
            this.viewport.width = window.innerWidth 
        },
        goTo (e, path) {
            console.log(e)
            e.preventDefault()

            if(path != '/app') {
                path = `/w/${path}`
            }
            this.$router.push({
                path: path,
            })
        },
    },
    created() {
        this.windowResized()
        let th = this
        window.addEventListener('resize', th.windowResized);        
    },
    destroyed() {
        let th = this
        window.removeEventListener('resize', th.windowResized);
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    }
}
</script>